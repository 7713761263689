// extracted by mini-css-extract-plugin
export var banner = "grid-images-module--banner--+AoHZ";
export var bottom = "grid-images-module--bottom--Fk5zf";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var center = "grid-images-module--center--aUneW";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var grid = "grid-images-module--grid--auYsT";
export var icon = "grid-images-module--icon--Wy5AT";
export var image = "grid-images-module--image--uFGe1";
export var left = "grid-images-module--left--lDhH8";
export var right = "grid-images-module--right--p2cnj";
export var text = "grid-images-module--text--9t7bN";
export var title = "grid-images-module--title--qAtMo";
export var top = "grid-images-module--top--cmdTw";