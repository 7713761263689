// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var content = "intro-teaser-module--content--Y7Zdg";
export var teaser = "intro-teaser-module--teaser--qEa0h";
export var title = "intro-teaser-module--title--4Jomt";