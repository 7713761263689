import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { Meta } from "./common/meta";
import { Intro } from "./spa/intro";
import { Grid } from "./spa/grid";
import { Services } from "./spa/services";
import { Offers } from "./spa/offers";

const Template = ({ data }) => {
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.spa.intro");
  const services = getProperty(data, "markdownRemark.frontmatter.spa.services");
  const grid = getProperty(data, "markdownRemark.frontmatter.spa.grid");
  const offers = getProperty(data, "markdownRemark.frontmatter.spa.offers");

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Services data={services} />
      <Grid data={grid} />
      <Offers data={offers} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        spa {
          intro {
            title
            content
            images {
              childImageSharp {
                gatsbyImageData(width: 750, layout: CONSTRAINED)
              }
            }
          }

          services {
            title
            labels
            images {
              publicURL
            }
          }

          grid {
            images {
              top_left {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              top_right {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              bottom_left {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              bottom_right {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
            }
            texts {
              title
              content
              banner
            }
          }

          offers {
            title
            content
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            items {
              label
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
