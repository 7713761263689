import PropTypes from "prop-types";

import { Section, getProperty } from "@gh/shared";

import { IntroTeaser } from "../common/intro-teaser";
import { CarouselGallery } from "../common/carousel-gallery";

export const Intro = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const images = getProperty(data, "images") || [];

  return (
    <Section align="center">
      <IntroTeaser
        title={title}
        content={content}
        teaser={<CarouselGallery images={images} />}
      />
    </Section>
  );
};

Intro.defaultProps = {
  data: {},
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
