// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "image-card-module--container--DH0gh";
export var content = "image-card-module--content--sDnX4";
export var fullscreen = "image-card-module--fullscreen--nUTU5";
export var image = "image-card-module--image--nFh7u";
export var left = "image-card-module--left--s+sQu";
export var right = "image-card-module--right---9MZV";