import PropTypes from "prop-types";

import { Section, getProperty } from "@gh/shared";

import { ServicesList } from "../common/services-list";

export const Services = ({ data }) => {
  const title = getProperty(data, "title");
  const labels = getProperty(data, "labels") || [];
  const images = getProperty(data, "images") || [];

  return (
    <Section noPaddingTop={true} align="center">
      <ServicesList title={title} images={images} labels={labels} />
    </Section>
  );
};

Services.defaultProps = {
  data: {},
};

Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};
