import PropTypes from "prop-types";

import { Container, Title, Text, Markdown, getProperty, ArrowCurvedIcon } from "@gh/shared";

import * as styles from "./grid-images.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

export const GridImages = ({ images, texts }) => {
  const title = getProperty(texts, "title");
  const content = getProperty(texts, "content");
  const banner = getProperty(texts, "banner");

  const imageTopLeft = getProperty(images, "top_left.childImageSharp.gatsbyImageData");
  const imageTopRight = getProperty(images, "top_right.childImageSharp.gatsbyImageData");
  const imageBottomLeft = getProperty(images, "bottom_left.childImageSharp.gatsbyImageData");
  const imageBottomRight = getProperty(images, "bottom_right.childImageSharp.gatsbyImageData");

  return (
    <Container>
      <div className={styles.grid}>
        <div className={styles.left}>
          <div className={styles.top}>
            <GatsbyImage image={imageTopLeft} alt="" className={styles.image} />
          </div>
          <div className={styles.center}>
            <div className={styles.banner}>
              <ArrowCurvedIcon className={styles.icon} />
              <Text family="f3" size="s6" className={styles.text}>
                {banner}
              </Text>
            </div>
          </div>
          <div className={styles.bottom}>
            <GatsbyImage image={imageBottomLeft} alt="" className={styles.image} />
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.top}>
            <GatsbyImage image={imageTopRight} alt="" className={styles.image} />
          </div>
          <div className={styles.center}>
            <Title wrapper="h1" size="s7" className={styles.title}>
              {title}
            </Title>
            <Text>
              <Markdown content={content} />
            </Text>
          </div>
          <div className={styles.bottom}>
            <GatsbyImage image={imageBottomRight} alt="" className={styles.image} />
          </div>
        </div>
      </div>
    </Container>
  );
};

GridImages.defaultProps = {
  images: {},
  texts: {},
};

GridImages.propTypes = {
  images: PropTypes.shape({
    top_left: PropTypes.object.isRequired,
    top_right: PropTypes.object.isRequired,
    bottom_left: PropTypes.object.isRequired,
    bottom_right: PropTypes.object.isRequired,
  }),
  texts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
  }),
};
