import PropTypes from "prop-types";

import { Container, getProperty, Title, Text } from "@gh/shared";

import * as styles from "./services-list.module.scss";

export const ServicesList = ({ title, images, labels }) => {
  return (
    <Container>
      <Title size="s4" family="f2" weight="w4" transform="uppercase">
        {title}
      </Title>

      {Array.isArray(images) && (
        <div className={styles.images}>
          {images.map((image, index) => {
            const imgSrc = getProperty(image, "publicURL");
            return <img key={index} src={imgSrc} className={styles.img} />;
          })}
        </div>
      )}

      {labels.map((label, index) => (
        <Text key={index} size="s5" color="primary">
          {label}
        </Text>
      ))}
    </Container>
  );
};

ServicesList.defaultProps = {
  title: null,
  images: undefined,
  labels: [],
};

ServicesList.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object.isRequired),
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
