import { useMemo } from "react";
import PropTypes from "prop-types";

import { ArrowIcon, removeProperty } from "@gh/shared";

import * as styles from "./nav-button.module.scss";

const DIRECTION_PREV = "prev";
const DIRECTION_NEXT = "next";

const NavButton = ({ direction, className, label, wrapper: Wrapper, ...props }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, styles[direction], className].join(" "),
    [direction, className]
  );

  return (
    <Wrapper {...props} className={wrapperClassName}>
      <ArrowIcon className={styles.icon} />
      {label && <span>{label}</span>}
    </Wrapper>
  );
};

NavButton.defaultProps = {
  label: null,
  className: "",
  wrapper: "button",
  direction: DIRECTION_NEXT,
};

NavButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  wrapper: PropTypes.elementType,
  direction: PropTypes.oneOf([DIRECTION_PREV, DIRECTION_NEXT]).isRequired,
};

export const ButtonPrev = (props) => <NavButton {...props} direction={DIRECTION_PREV} />;

ButtonPrev.propTypes = removeProperty(NavButton.propTypes, "direction");

export const ButtonNext = (props) => <NavButton {...props} direction={DIRECTION_NEXT} />;

ButtonNext.propTypes = removeProperty(NavButton.propTypes, "direction");
