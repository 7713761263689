import PropTypes from "prop-types";

import { Container, Title, Text, Markdown } from "@gh/shared";

import * as styles from "./intro-teaser.module.scss";

export const IntroTeaser = ({ teaser, title, content }) => (
  <Container>
    {teaser && <div className={styles.teaser}>{teaser}</div>}
    <Title wrapper="h1" size="s7" className={styles.title} weight="w4" color="primary">
      {title}
    </Title>
    <Text className={styles.content}>
      <Markdown content={content} />
    </Text>
  </Container>
);

IntroTeaser.defaultProps = {
  teaser: null,
  title: null,
  content: null,
};

IntroTeaser.propTypes = {
  teaser: PropTypes.element,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
