import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import cssEscape from "css.escape";

import { getProperty } from "@gh/shared";

import { ButtonNext, ButtonPrev } from "./nav-button";

import * as styles from "./carousel-gallery.module.scss";

const prevEl = `.${cssEscape(styles.prev)}`;
const nextEl = `.${cssEscape(styles.next)}`;

export const CarouselGallery = ({ images, showDots }) => (
  <div className={styles.wrapper}>
    <Swiper
      spaceBetween={0}
      autoHeight={true}
      className={styles.slider}
      navigation={{ prevEl, nextEl }}
      modules={[Navigation]}
    >
      {images.map((item, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage image={getProperty(item, "childImageSharp.gatsbyImageData")} alt="" />
          </SwiperSlide>
      ))}
    </Swiper>
    <ButtonPrev className={styles.prev} />
    <ButtonNext className={styles.next} />
  </div>
);

CarouselGallery.defaultProps = {
  images: [],
};

CarouselGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};
