import { useMemo } from "react";
import PropTypes from "prop-types";

import { Container, removeProperty } from "@gh/shared";

import * as styles from "./image-card.module.scss";

const LAYOUT_LEFT = "left";
const LAYOUT_RIGHT = "right";

const ImageCard = ({ content, layout, image, fullScreen }) => {
  const wrapperClassName = useMemo(
    () => [styles[layout], fullScreen ? styles.fullscreen : ""].join(" "),
    [layout, fullScreen]
  );

  return (
    <div className={wrapperClassName}>
      <Container>
        <div className={styles.container}>
          <div className={styles.image}>{image}</div>
          <div className={styles.content}>{content}</div>
        </div>
      </Container>
    </div>
  );
};

ImageCard.defaultProps = {
  image: null,
  content: null,
  fullScreen: false,
  layout: LAYOUT_LEFT,
};

ImageCard.propTypes = {
  image: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  fullScreen: PropTypes.bool,
  layout: PropTypes.oneOf([LAYOUT_LEFT, LAYOUT_RIGHT]).isRequired,
};

export const ImageCardLeft = (props) => <ImageCard {...props} layout={LAYOUT_LEFT} />;

ImageCardLeft.propTypes = removeProperty(ImageCard.propTypes, "layout");

export const ImageCardRight = (props) => <ImageCard {...props} layout={LAYOUT_RIGHT} />;

ImageCardRight.propTypes = removeProperty(ImageCard.propTypes, "layout");
