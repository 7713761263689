import { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, ButtonArrow, getProperty, Title, Text, Markdown } from "@gh/shared";

import { ImageCardRight } from "../common/image-card";

import * as styles from "./offers.module.scss";

export const Offers = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const items = getProperty(data, "items") || [];
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section noPaddingTop={true}>
      <div className={styles.items}>
        {items.map((item, index) => {
          const label = getProperty(item, "label");
          const url = getProperty(item, "file.publicURL");

          return (
            <ButtonArrow key={index} href={url} wrapper="a" target="_blank" rel="noreferrer">
              {label}
            </ButtonArrow>
          );
        })}
      </div>

      <ImageCardRight
        fullScreen={true}
        content={
          <Fragment>
            <Title>{title}</Title>
            <Text>
              <Markdown content={content} />
            </Text>
          </Fragment>
        }
        image={<GatsbyImage image={image} alt="" />}
      />
    </Section>
  );
};

Offers.defaultProps = {
  data: {},
};

Offers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        file: PropTypes.object.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
